<template>
    <div class="page">
        <HeadContent>
            <img slot="bgImg" src="../../assets/common/topTitleBg2.png" style="margin-top: 70px">
            <div slot="title" style="width: 100%">认证认可</div>
        </HeadContent>
        <div style="width: 1200px;margin: 0 auto;padding: 90px 0 40px">
            <div class="mtitle">认证认可</div>
        </div>
        <div class="fir_box">
            <!--      第一个盒子-->
            <div class="box1">
                <div class="pic1">
                    <img src="./images/certification/蒙版组 181.png" alt="">
                </div>
                <div class="hover1" @click="goRouter('attestation',{title:'食品生产许可咨询认证'})">
                    <div class="spsc">
                        食品生产许可
                    </div>
                    <div class="spscc">
                        咨询认证
                    </div>
                    <div class="fuhao">
                        [SC]
                    </div>
                </div>
            </div>
            <!--      第二个盒子-->
            <div class="box2">
                <div class="pic2">
                    <img src="./images/certification/蒙版组 196.png" alt="">
                </div>
                <div class="hover2" @click="goRouter('attestation',{title:'质量管理体系认证'})">
                    <div class="spsc">
                        质量管理体系
                    </div>
                    <div class="spscc">
                        认证
                    </div>
                    <div class="fuhao">
                        [QMS]
                    </div>
                    <div class="bianhao">
                        (ISO9001)
                    </div>
                </div>
            </div>
            <!--第三个盒子-->
            <div class="box3">
                <div class="pic3">
                    <img src="./images/certification/蒙版组 198.png" alt="">
                </div>
                <div class="hover3" @click="goRouter('attestation',{title:'有机产品'})">
                    <div class="spsc">
                        有机产品认证
                    </div>
                    <div class="spscc">
                        认证
                    </div>
                    <div class="fuhao">
                        [SC]
                    </div>
                    <!--          <div class="bianhao">-->
                    <!--            (ISO9001)-->
                    <!--          </div>-->
                </div>
            </div>
        </div>
        <!--    第二行-->
        <div class="sec_box">
            <!--      第一个盒子-->
            <div class="box11">
                <div class="pic11">
                    <img src="./images/certification/蒙版组 200.png" alt="">
                </div>
                <div class="hover1" @click="goRouter('attestation',{title:'环境管理体系认证'})">
                    <div class="spsc">
                        环境管理体系
                    </div>
                    <div class="spscc">
                        认证
                    </div>
                    <div class="fuhao">
                        [EMS]
                    </div>
                    <div class="bianhao">
                        (ISO14001)
                    </div>
                </div>
            </div>
            <!--      第二个盒子-->
            <div class="box22">
                <div class="pic22">
                    <img src="./images/certification/蒙版组 202.png" alt="">
                </div>
                <div class="hover1_1" @click="goRouter('attestation',{title:'职业健康安全体系认证'})">
                    <div class="spsc">
                        职业健康安全
                    </div>
                    <div class="spscc">
                        体系认证
                    </div>
                    <div class="fuhao">
                        [OHSMS]
                    </div>
                    <div class="bianhao">
                        (OSAS18001)
                    </div>
                </div>
            </div>
        </div>

        <!--    第三行-->
        <div class="thi_box">
            <!--      第一个盒子-->
            <div class="box111">
                <div class="pic111">
                    <img src="./images/certification/蒙版组 201.png" alt="">
                </div>
                <div class="hover1" @click="goRouter('attestation',{title:'食品安全管理体系认证'})">
                    <div class="spsc">
                        食品安全管理
                    </div>
                    <div class="spscc">
                        体系认证
                    </div>
                    <div class="fuhao">
                        [FSMS]
                    </div>
                    <div class="bianhao">
                        (ISO22000)
                    </div>
                </div>
            </div>
            <!--      第二个盒子-->
            <div class="box222">
                <div class="pic222">
                    <img src="./images/certification/摄图网_501120999_新鲜果蔬（企业商用）.png" alt="">
                </div>
                <div class="hover1_1" @click="goRouter('attestation',{title:'食品危害分析与关键控制点'})">
                    <div class="spsc">
                        食品危害分析与
                    </div>
                    <div class="spscc">
                        关键控制点
                    </div>
                    <div class="fuhao">
                        [HACCP]
                    </div>
                    <div class="bianhao">
                        (GB/T19538-2004)
                    </div>
                </div>
            </div>
        </div>

        <!--    选择我们 s-->
        <div style="width: 1200px;margin: 0 auto;padding: 90px 0 0">
            <div class="mtitle">选择我们</div>
        </div>
        <zxhz></zxhz>
        <!--    选择我们 e-->
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        methods: {
            goRouter(roter, query) {
                this.$router.push({
                    name: roter,
                    query
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../styles/mixin.scss";

    * {
        margin: 0;
        padding: 0;
    }

    .page {
        overflow-x: visible;
    }

    .banner {
        position: relative;
        width: 100%;
        height: 250px;
        display: flex;
        justify-content: center;
        opacity: 1;
    }

    .rzrk {
        position: absolute;
        width: 1200px;
        height: 250px;
        color: #fff;
        font-size: 50px;
    }

    .rzrk .rzrks {
        position: absolute;
        left: 15%;
        top: 50%;
    }

    .center, .center1 {
        height: 132px;
        width: 1200px;
        margin: 0 auto;
        display: flex;
    }

    .center1 {
        height: 65px;
    }

    .word, .word1 {
        width: 87px;
        height: 32px;
        opacity: 1;
        padding-top: 94px;
        margin-left: 10px;
    }

    .word1 {
        padding-top: 41px;
    }

    .juxing, .juxing1 {
        width: 87px;
        height: 32px;
        opacity: 1;
        margin-top: 88px;
        margin-left: 0;
        position: absolute;
    }

    .juxing1 {
        margin-top: 35px;
    }

    .fir_box, .sec_box, .thi_box {
        width: 1200px;
        height: 521px;
        margin: 0 auto;
        display: flex;
    }

    .sec_box, .thi_box {
        margin-top: 35px;
    }

    .box1, .box11, .box111 {
        width: 537px;
        height: 521px;
    }

    .box22, .box222 {
        width: 613px;
        height: 521px;
        margin-left: 50px;
    }

    .box2, .box3 {
        width: 293px;
        height: 521px;
        margin-left: 50px;
    }

    .box3 {
        margin-left: 27px;
    }

    .pic1, .pic11, .pic22, .pic111, .pic222 {
        width: 537px;
        height: 475px;
        position: absolute;
    }

    .pic22, .pic222 {
        width: 613px;
    }

    .pic2, .pic3 {
        width: 293px;
        height: 475px;
        opacity: 1;
        background: #666;
        position: absolute;
    }

    .hover1, .hover2, .hover3, .hover1_1 {
        width: 294px;
        height: 283px;
        background: #002fdf;
        opacity: 0.44;
        margin-top: 238px;
        margin-left: 244px;
        display: flex;

        &:hover {
            background: #1320E8;
            cursor: pointer;
        }
    }

    .hover1_1 {
        margin-left: 320px;
    }

    .hover2, .hover3 {
        margin-left: 0;
    }

    .spsc, .spscc {
        height: 84px;
        font-size: 30px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0px;
        color: #FFFFFF;
        letter-spacing: 5px;
        opacity: 1;
        margin-top: 46px;
        margin-left: 35px;
        position: absolute;
    }

    .spscc {
        margin-top: 80px;
    }

    .fuhao {
        width: 145px;
        height: 28px;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        line-height: 0px;
        color: #FFFFFF;
        letter-spacing: 1px;
        opacity: 1;
        position: absolute;
        margin-top: 190px;
        margin-left: 35px;
    }

    .bianhao {
        width: 69px;
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 0px;
        color: #FFFFFF;
        letter-spacing: 1px;
        opacity: 0.8;
        position: absolute;
        margin-top: 193px;
        margin-left: 192px;
    }

    .footer {
        width: 100%;
        height: 447px;
        margin-top: 132px;
        background: #FBFBFB;
        //background: #7eaf0a;
        opacity: 1;
        border-radius: 0px;
    }

    .p {
        width: 1200px;
        height: 380px;
        margin: 0 auto;
        display: flex;

        .picture, .picture1 {
            width: 74px;
            height: 74px;
            margin-top: 90px;
            margin-left: 90px;
            position: absolute;
        }

        .picture1 {
            margin-top: 213px;
        }

        .fwzx_box, .fwzx_box1 {
            width: 125px;
            height: 43px;
            background: #F5F5F5;
            opacity: 1;
            margin-top: 110px;
            margin-left: 193px;
            position: absolute;
        }

        .fwzx_box1 {
            margin-top: 228px;
        }

        .fwzx, .fwzx1 {
            width: 109px;
            height: 28px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 600;
            line-height: 0px;
            color: #606060;
            letter-spacing: 5px;
            margin-top: 130px;
            margin-left: 206px;
            position: absolute;
            opacity: 1;
        }

        .fwzx1 {
            margin-top: 248px;
        }

        .person {
            width: 538px;
            height: 301px;
            opacity: 1;
            background: #999;
            margin-left: 638px;
        }

        .blue {
            width: 24px;
            height: 229px;
            background: #1320E8;
            opacity: 1;
            margin-top: 72px;
        }
    }


</style>
